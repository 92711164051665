"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.sort");

var _defineProperty2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vuex = require("vuex");

var _axios = _interopRequireDefault(require("axios"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// import { SliderPicker } from 'vue-color'
var _default = {
  components: {// SliderPicker
  },
  data: function data() {
    return {
      productData: [],
      productTotal: 0,
      dialogTitle: '코드 등록',
      codeRegistDialog: false,
      visibleWebTicket: false,
      ticketUrl: 'about:blank',
      partners: [],
      options: {},
      nativeObj: function nativeObj() {
        return {
          partner_id: '',
          product_code: '',
          name: '',
          ticket: 0,
          event1_name: '비치 타올',
          event1_count: 0,
          event2_name: '음료',
          event2_count: 0,
          price: 0,
          supply: 0,
          data: {
            ticketGuide: ''
          }
        };
      },
      param: {
        page: 1,
        partner_id: undefined
      },
      registObj: {}
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['token', 'roles'])), {}, {
    selectedPartnerOptions: function selectedPartnerOptions() {
      var _this = this;

      if (this.registObj.partner_id) {
        return this.partners.filter(function (o) {
          return o.id === _this.registObj.partner_id;
        }).pop().options || [];
      }

      return [];
    }
  }),
  watch: {
    param: {
      deep: true,
      handler: function handler(v) {
        this.handleSetData();
      }
    }
  },
  created: function created() {
    var _this2 = this;

    if (this.roles.indexOf('admin') >= 0) {
      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/partner/list")).then(function (res) {
        var data = res.data;

        if (!data.result) {
          console.log(data.data);
        }

        _this2.partners = data.data.result.sort(function (a, b) {
          return a.name < b.name ? -1 : 1;
        });
      });
    } else {
      this.param.partner_id = this.token;
    }

    this.registObj = this.nativeObj();
    this.handleSetData();
  },
  methods: {
    handleSetData: function handleSetData() {
      var _this3 = this;

      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/product/list"), this.param).then(function (res) {
        var data = res.data;

        if (!data.result) {
          console.log(data.data);
        }

        _this3.productData = data.data.result;
        _this3.productTotal = data.data.total;
      });
    },
    codeRegist: function codeRegist() {
      var _this4 = this;

      var data = this.registObj;
      data.options = this.options;

      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/product/").concat(this.dialogTitle === '코드 등록' ? 'add' : 'modify'), data).then(function (res) {
        var data = res.data;

        if (!data.result) {
          console.log(data.data);
        } else {
          alert('저장했습니다.');
        }

        _this4.handleSetData(); // this.codeRegistDialog = false

      });
    },
    codeModify: function codeModify(val) {
      if (!val.data) val.data = {};
      this.options = val.options || {};
      this.dialogTitle = '코드 수정';
      this.registObj = val;
      this.codeRegistDialog = true;
    },
    codeRemove: function codeRemove(val) {
      var _this5 = this;

      if (!confirm('정말 삭제하시겠습니까?')) return;

      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/product/remove"), {
        seq: val.seq
      }).then(function (res) {
        var data = res.data;

        if (!data.result) {
          console.log(data.data);
        }

        _this5.handleSetData();

        _this5.codeRegistDialog = false;
      });
    },
    handleShowWebTickets: function handleShowWebTickets() {
      this.ticketUrl = "".concat(process.env.VUE_APP_FRONT, "/pc_").concat(this.registObj.product_code, "?v=").concat(Date.now());
      this.visibleWebTicket = true;
    }
  }
};
exports.default = _default;