"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.getInfo = getInfo;
exports.logout = logout;

require("core-js/modules/es6.regexp.search");

var _request = _interopRequireDefault(require("@/utils/request"));

var _axios = _interopRequireDefault(require("axios"));

function login(data) {
  if (data.username.indexOf('@') >= 0) {
    return _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/auth"), data);
  }

  return (0, _request.default)({
    url: '/user/login',
    method: 'post',
    data: data
  });
}

function getInfo(token) {
  if (String(token).search(/[0-9]+/) >= 0) {
    return _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/user"), {
      id: token
    });
  }

  return (0, _request.default)({
    url: '/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}

function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}