var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "p",
        { staticStyle: { float: "right" } },
        [
          _vm.roles.indexOf("admin") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      ;(_vm.dialogTitle = "등록"),
                        (_vm.registObj = _vm.nativeObj()),
                        (_vm.registDialog = true)
                    }
                  }
                },
                [_vm._v("\n      등록\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.registDialog },
          on: {
            "update:visible": function($event) {
              _vm.registDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "파트너사명", "label-width": "90px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "파트너사명" },
                    model: {
                      value: _vm.registObj.name,
                      callback: function($$v) {
                        _vm.$set(_vm.registObj, "name", $$v)
                      },
                      expression: "registObj.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "관리자", "label-width": "90px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "이메일", autocomplete: "off" },
                    model: {
                      value: _vm.registObj.email,
                      callback: function($$v) {
                        _vm.$set(_vm.registObj, "email", $$v)
                      },
                      expression: "registObj.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "비밀번호", "label-width": "90px" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-right": "20px" },
                      attrs: { span: 12 }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "비밀번호",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.registObj.password,
                          callback: function($$v) {
                            _vm.$set(_vm.registObj, "password", $$v)
                          },
                          expression: "registObj.password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "확인",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.registObj.confirm,
                          callback: function($$v) {
                            _vm.$set(_vm.registObj, "confirm", $$v)
                          },
                          expression: "registObj.confirm"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.feedback.password
                    ? _c("div", { staticClass: "feedback" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.feedback.password) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.feedback.confirm
                    ? _c("div", { staticClass: "feedback" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.feedback.confirm) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "체크인 코드", "label-width": "90px" } },
                [
                  _c("el-alert", {
                    attrs: {
                      title:
                        "티켓화면에서 체크인처리를 할 때 입력할 코드를 6자 이상 입력하세요.",
                      "show-icon": "",
                      type: "info",
                      closable: false
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.registObj.checkin, function(item, index) {
                    return _c(
                      "el-tag",
                      {
                        key: item.code,
                        attrs: { closable: "", "disable-transitions": false },
                        on: {
                          close: function($event) {
                            return _vm.handleRemoveCode(index)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.code) + "\n          "
                        ),
                        item.name
                          ? _c("span", [_vm._v("(" + _vm._s(item.name) + ")")])
                          : _vm._e()
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-right": "10px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c("el-input", {
                            ref: "checkinCode",
                            attrs: {
                              minlength: "6",
                              placeholder: "인증코드 (6자이상)",
                              size: "small"
                            },
                            model: {
                              value: _vm.checkin.code,
                              callback: function($$v) {
                                _vm.$set(_vm.checkin, "code", $$v)
                              },
                              expression: "checkin.code"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-right": "10px" },
                          attrs: { span: 6 }
                        },
                        [
                          _c("el-input", {
                            ref: "checkinName",
                            attrs: {
                              placeholder: "설명 (데스크, 까페 등)",
                              size: "small",
                              autosize: ""
                            },
                            model: {
                              value: _vm.checkin.name,
                              callback: function($$v) {
                                _vm.$set(_vm.checkin, "name", $$v)
                              },
                              expression: "checkin.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleAddCode }
                            },
                            [_vm._v("+ 추가")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "바우처", "label-width": "90px" } },
                [
                  _c("el-alert", {
                    attrs: {
                      title: "입장권 이외의 바우처(증정티켓)를 입력하세요.",
                      "show-icon": "",
                      type: "info",
                      closable: false
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.registObj.options, function(option, index) {
                    return _c(
                      "el-tag",
                      {
                        key: option,
                        attrs: { closable: "", "disable-transitions": false },
                        on: {
                          close: function($event) {
                            return _vm.handleRemoveOption(index)
                          }
                        }
                      },
                      [_vm._v("\n          " + _vm._s(option) + "\n        ")]
                    )
                  }),
                  _vm._v(" "),
                  _vm.inputOptionVisible
                    ? _c("el-input", {
                        ref: "saveOptionInput",
                        staticClass: "input-new-option",
                        attrs: { size: "small", autosize: "" },
                        on: { blur: _vm.handleInputOption },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleInputOption($event)
                          }
                        },
                        model: {
                          value: _vm.newOptionValue,
                          callback: function($$v) {
                            _vm.newOptionValue = $$v
                          },
                          expression: "newOptionValue"
                        }
                      })
                    : _c(
                        "el-button",
                        {
                          staticClass: "button-new-option",
                          attrs: { size: "small" },
                          on: { click: _vm.showInputOption }
                        },
                        [_vm._v(" + 새 항목 ")]
                      )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "티켓상단배너", "label-width": "90px" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "el-upload",
                      attrs: {
                        action: "#",
                        "list-type": "picture-card",
                        "file-list": _vm.banner_top,
                        multiple: false,
                        limit: 2,
                        "auto-upload": false,
                        "show-file-list": true,
                        "on-change": _vm.handelUploadChange,
                        "on-remove": _vm.handelUploadRemove
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "default" }, slot: "default" },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [
                          _vm._v("jpg/png 포맷 "),
                          _c("small", [_vm._v(" | ")]),
                          _vm._v(" 가로 1000px 이상")
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "티켓하단안내", "label-width": "90px" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: "10" },
                    model: {
                      value: _vm.registObj.data.ticketGuide,
                      callback: function($$v) {
                        _vm.$set(_vm.registObj.data, "ticketGuide", $$v)
                      },
                      expression: "registObj.data.ticketGuide"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-alert",
                {
                  staticClass: "tools",
                  attrs: { type: "info", closable: false }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "button", size: "small" },
                      on: { click: _vm.handleShowWebTickets }
                    },
                    [_vm._v("티켓보기")]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("  ※ 저장 후 클릭하세요.")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.registDialog = false
                    }
                  }
                },
                [_vm._v("취소")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.codeRegist } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dialogTitle === "등록" ? "등록" : "수정") +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: {},
          attrs: {
            data: _vm.data,
            "header-align": "center",
            stripe: "",
            fit: "",
            size: "small"
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "파트너사", prop: "name", "min-width": "90" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "이메일", prop: "email", "min-width": "90" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "바우처", prop: "options", "min-width": "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s((scope.row.options || ["-"]).join(", ")) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "등록 일자",
              prop: "date_create",
              "min-width": "90"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "수정", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.modify(scope.row)
                          }
                        }
                      },
                      [_vm._v("수정")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "티켓보기",
            width: "375px",
            visible: _vm.visibleWebTicket
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleWebTicket = $event
            }
          }
        },
        [
          _c("iframe", {
            attrs: { width: "100%", height: "600", src: _vm.ticketUrl }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }