"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.object.values");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vuex = require("vuex");

var _axios = _interopRequireDefault(require("axios"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'DashboardAdmin',
  components: {},
  data: function data() {
    return {
      smsData: [],
      totalData: [],
      channelData: [],
      partnerData: [],
      dailyData: [],
      partners: [{
        value: '',
        text: '전체파트너'
      }],
      channels: [{
        value: '',
        text: '전체채널'
      }],
      partner_id: '',
      channel_id: '',
      switchBtn: false,
      value1: [new Date(), new Date()],
      value2: [new Date(), new Date()],
      datePickerOptions: {
        shortcuts: [{
          text: '이번주',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setDate(start.getDate() - start.getDay());
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '이번달',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setDate(1);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '지난 3개월',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setMonth(start.getMonth() - 2);
            start.setDate(1);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      monthPickerOptions: {
        shortcuts: [{
          text: '이번 달',
          onClick: function onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: '이번 해',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '지난 6개월',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setMonth(start.getMonth() - 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['token', 'roles'])), {}, {
    datePickerDefaultValue: function datePickerDefaultValue() {
      var d = new Date();
      d.setMonth(new Date().getMonth() - 1);
      return [d, new Date()];
    }
  }),
  watch: {
    value1: function value1() {
      this.handleSetData();
    },
    value2: function value2() {
      this.handleSetData();
    },
    partner_id: function partner_id() {
      this.handleSetData();
    },
    channel_id: function channel_id() {
      this.handleSetData();
    }
  },
  created: function created() {
    var _this = this;

    // this.value2 = [new Date(), new Date()]
    _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/partner/list")).then(function (res) {
      var data = res.data;

      if (!data.result) {
        console.log(data.data);
      }

      data.data.result.map(function (o) {
        return _this.partners.push({
          value: o.id,
          text: o.name
        });
      });

      _this.$forceUpdate();
    });

    _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/channel/list")).then(function (res) {
      var data = res.data;

      if (!data.result) {
        console.log(data.data);
      }

      data.data.result.map(function (o) {
        return _this.channels.push({
          value: o.id,
          text: o.name
        });
      });

      _this.$forceUpdate();
    });

    this.handleSetData();
  },
  methods: {
    smsHeaderStyle: function smsHeaderStyle(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;
      return {
        'backgroundColor': '#343F52',
        'color': 'white'
      };
    },
    ticketHeaderStyle: function ticketHeaderStyle() {
      return {
        'backgroundColor': '#343F52',
        'color': 'white'
      };
    },
    handleSetData: function handleSetData() {
      var _this2 = this;

      this.smsData = [];
      this.ticketData = [];
      var body = {
        page: 1,
        type: this.switchBtn ? 'date' : 'month',
        date_start: '201909',
        date_end: '201910',
        channel: this.channel_id,
        partner: this.partner_id
      };

      if (this.value1 === '' && this.value2 === '') {
        body.date_start = String(new Date().getFullYear()) + String('0' + (new Date().getMonth() + 1)).slice(-2);
        body.date_end = String(new Date().getFullYear()) + String('0' + (new Date().getMonth() + 2)).slice(-2);
      } else if (this.switchBtn) {
        var sYear = String(this.value1[0].getFullYear());
        var sMonth = String('0' + (this.value1[0].getMonth() + 1)).slice(-2);
        var sDay = String('0' + this.value1[0].getDate()).slice(-2);
        body.date_start = sYear + sMonth + sDay;
        var eYear = String(this.value1[1].getFullYear());
        var eMonth = String('0' + (this.value1[1].getMonth() + 1)).slice(-2);
        var eDay = String('0' + this.value1[1].getDate()).slice(-2);
        body.date_end = eYear + eMonth + eDay;
      } else if (!this.switchBtn) {
        var _sYear = String(this.value2[0].getFullYear());

        var _sMonth = String('0' + (this.value2[0].getMonth() + 1)).slice(-2);

        body.date_start = _sYear + _sMonth;

        var _eYear = String(this.value2[1].getFullYear());

        var _eMonth = String('0' + (this.value2[1].getMonth() + 2)).slice(-2);

        body.date_end = _eYear + _eMonth;
      }

      this.totalData = [];
      this.dailyData = [];

      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/stat/ticket"), body).then(function (res) {
        if (res.data.result) {
          var partners = {};
          var total = {
            amount: 0,
            orders: 0,
            tickets: 0,
            used: 0,
            cancelled: 0,
            refund: 0
          };
          res.data.data.list.sort(function (o1, o2) {
            return new Date(o1.date) < new Date(o2.date) ? 1 : -1;
          }).map(function (o) {
            total.orders += Number(o.orders);
            total.tickets += Number(o.tickets);
            total.used += Number(o.used);
            total.cancelled += Number(o.cancelled);
            total.refund += Number(o.refunds);
            var partner = partners[o.partner_id];

            if (!partner) {
              partner = partners[o.partner_id] = {
                id: o.partner_id,
                channels: {}
              };
            }

            var channel = partner.channels[o.channel_id];

            if (!channel) {
              channel = partners[o.partner_id].channels[o.channel_id] = {
                channel_id: o.channel_id,
                amount: 0,
                orders: 0,
                tickets: 0,
                used: 0,
                cancelled: 0,
                refund: 0
              };
            }

            channel.orders += Number(o.orders);
            channel.tickets += Number(o.tickets);
            channel.used += Number(o.used);
            channel.cancelled += Number(o.cancelled);
            channel.refund += Number(o.refunds);

            _this2.dailyData.push(o);
          });

          _this2.totalData.push(total);

          _this2.partnerData = Object.values(partners);
        }
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};
exports.default = _default;