"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vuex = require("vuex");

var _index = _interopRequireDefault(require("@/components/UploadExcel/index.vue"));

var _fxjs = _interopRequireDefault(require("fxjs"));

var _axios = _interopRequireDefault(require("axios"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'UploadExcel',
  components: {
    UploadExcelComponent: _index.default
  },
  data: function data() {
    return {
      channels: [],
      partners: {},
      partner_id: '1',
      tableData: [],
      tableHeader: [],
      loading: false,
      disable: true,
      file: ''
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['token', 'roles'])),
  created: function created() {
    var _this = this;

    if (this.roles.indexOf('admin') < 0) {
      this.partner_id = this.token;
    }

    _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/channel/list")).then(function (res) {
      var data = res.data;

      if (!data.result) {
        console.log(data.data);
      }

      _this.channels = data.data.result;
    });

    _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/partner/list")).then(function (res) {
      var data = res.data;

      if (!data.result) {
        console.log(data.data);
      }

      data.data.result.map(function (o) {
        _this.partners[o.id] = o.name;
      });

      _this.$forceUpdate();
    });
  },
  methods: {
    submit: function submit() {
      var _this2 = this;

      this.loading = true;
      var data = {};
      data.lists = this.tableData;
      data.type = 'excel';
      data.user = 'admin';

      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/order/adds"), data).then(function (res) {
        if (res.data.result) {
          _this2.loading = false;
          _this2.disable = true;
          _this2.file = '';

          _this2.$notify({
            title: 'Success',
            message: '업로드가 완료 되었습니다.',
            type: 'success'
          });
        } else {
          _this2.loading = false;
          _this2.disable = true;
          _this2.file = '';

          _this2.$notify.error({
            title: 'Error',
            message: '업로드에 실패 하였습니다.'
          });
        }
      }).catch(function (err) {
        console.log(err);

        _this2.$notify.error({
          title: 'Error',
          message: '업로드에 실패 하였습니다.'
        });

        _this2.loading = false;
        _this2.disable = true;
        _this2.file = '';
      });
    },
    beforeUpload: function beforeUpload(file) {
      this.file = file.name;

      if (this.file.length > 0) {
        this.disable = false;
      }

      var isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning'
      });
      return false;
    },
    handleSuccess: function handleSuccess(_ref) {
      var _this3 = this;

      var results = _ref.results,
          header = _ref.header;
      console.log(header);

      var _json = _fxjs.default.go(_fxjs.default.L.range(2, Infinity), // _.L.map(a => results[a]),
      _fxjs.default.L.map(function (a) {
        var c = _this3.channels.filter(function (o) {
          return o.name === results[a]['매입처'];
        });

        if (c.length === 0) {
          results[a].channel_id = '1';
        } else {
          results[a].channel_id = String(c[0].id);
        }

        var p = _fxjs.default.filter(_this3.partners, function (o) {
          return o.name === results[a]['사용처'];
        });

        if (p.length === 0) {
          results[a]['사용처'] = _this3.partners[_this3.partner_id];
          results[a].partner_id = _this3.partner_id;
        } else {
          results[a].partner_id = p[0].id;
        }

        if (header.length < 30) return results[a];
        return {
          'MD명': '',
          'MD코드': '',
          '고객명': results[a]['인수자'],
          '고객번호': '',
          '기본번호': results[a]['인수자 HP'],
          '대상 ↵수량': results[a]['대상\r\n수량'],
          '뒷번호 ↵4자리': '',
          '매입처': results[a]['매입처'],
          '매출처': '',
          '사용처': results[a]['사용처'],
          '메시지': results[a]['고객메시지'],
          '발송구분': '',
          '상품명': results[a]['상품명'],
          '상품코드': results[a]['상품'],
          '속성': results[a]['속성'],
          '속성명': results[a]['속성명'],
          '순번': results[a]['순번'],
          '요청번호': results[a]['인수자 HP'],
          '접수일자': results[a]['출고요청일'],
          '주문 ↵수량': results[a]['요청\r\n수량'],
          '주문번호': results[a]['주문번호'],
          '진행상태': '',
          '출고 ↵수량': results[a]['출고\r\n수량'],
          '취소 ↵수량': results[a]['취소\r\n수량']
        };
      }), _fxjs.default.take(results.length - 2));

      this.tableData = _json;
      this.tableHeader = header;
    }
  }
};
exports.default = _default;