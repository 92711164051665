var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      ref: "excel-upload-input",
      staticClass: "excel-upload-input",
      attrs: { type: "file", accept: ".xlsx, .xls" },
      on: { change: _vm.handleClick }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "drop",
        on: {
          drop: _vm.handleDrop,
          dragover: _vm.handleDragover,
          dragenter: _vm.handleDragover
        }
      },
      [
        _vm._v("\n    엑셀 파일(.xlsx)을 떨어뜨리거나\n    "),
        _c(
          "el-button",
          {
            staticStyle: { "margin-left": "16px" },
            attrs: { loading: _vm.loading, size: "mini", type: "primary" },
            on: { click: _vm.handleUpload }
          },
          [_vm._v("\n      파일 선택\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }