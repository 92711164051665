var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "p",
        { staticStyle: { float: "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  ;(_vm.dialogTitle = "등록"),
                    (_vm.registObj = _vm.nativeObj),
                    (_vm.registDialog = true)
                }
              }
            },
            [_vm._v(" 등록 ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.registDialog },
          on: {
            "update:visible": function($event) {
              _vm.registDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "판매채널명", "label-width": "80px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "판매채널명" },
                    model: {
                      value: _vm.registObj.name,
                      callback: function($$v) {
                        _vm.$set(_vm.registObj, "name", $$v)
                      },
                      expression: "registObj.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.registDialog = false
                    }
                  }
                },
                [_vm._v("취소")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.codeRegist } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dialogTitle === "등록" ? "등록" : "수정") +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: {},
          attrs: {
            data: _vm.data,
            "header-align": "center",
            stripe: "",
            fit: "",
            size: "small"
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "판매채널", prop: "name", "min-width": "90" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "등록 일자",
              prop: "date_create",
              "min-width": "90"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "수정" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.codeModify(scope.row)
                          }
                        }
                      },
                      [_vm._v("\n          수정\n        ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }