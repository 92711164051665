var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "12px" } },
        [
          _c(
            "el-col",
            { staticClass: "search-form", attrs: { span: 16 } },
            [
              _vm.roles.indexOf("admin") >= 0 &&
              Object.values(_vm.partners).length > 0
                ? _c(
                    "el-select",
                    {
                      attrs: { placeholder: ":: 파트너" },
                      model: {
                        value: _vm.partner_id,
                        callback: function($$v) {
                          _vm.partner_id = $$v
                        },
                        expression: "partner_id"
                      }
                    },
                    _vm._l(_vm.partners, function(name, id) {
                      return _c("el-option", {
                        key: id,
                        attrs: { label: name, value: id }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "~",
                  "start-placeholder": "시작일",
                  "end-placeholder": "종료일",
                  "default-value": _vm.datePickerDefaultValue,
                  "picker-options": _vm.datePickerOptions,
                  "default-time": ["00:00:00", "23:59:59"]
                },
                model: {
                  value: _vm.filter.period,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "period", $$v)
                  },
                  expression: "filter.period"
                }
              }),
              _vm._v(" "),
              _c("el-input", {
                ref: "inputUserName",
                staticClass: "input",
                staticStyle: { width: "160px" },
                attrs: { placeholder: "고객명" },
                model: {
                  value: _vm.user_name,
                  callback: function($$v) {
                    _vm.user_name = $$v
                  },
                  expression: "user_name"
                }
              }),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  ref: "inputSearch",
                  staticClass: "input-with-select",
                  on: {
                    change: function($event) {
                      return _vm.searchList(_vm.select, _vm.search)
                    }
                  },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { slot: "prepend" },
                      slot: "prepend",
                      model: {
                        value: _vm.select,
                        callback: function($$v) {
                          _vm.select = $$v
                        },
                        expression: "select"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "휴대폰번호", value: "1" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "주문번호", value: "3" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "바코드번호", value: "4" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.searched
                    ? _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-close" },
                        on: { click: _vm.searchReset },
                        slot: "append"
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function($event) {
                        return _vm.searchList(_vm.select, _vm.search)
                      }
                    },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "total text-center", attrs: { span: 4 } },
            [_vm._v("\n      " + _vm._s(_vm.total) + " 건\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "text-right", attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v("엑셀 다운로드")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticStyle: { clear: "both", border: "none" } }),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0" } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.filter.type,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "type", $$v)
                    },
                    expression: "filter.type"
                  }
                },
                _vm._l(_vm.filters, function(item) {
                  return _c(
                    "el-radio-button",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.text))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-pagination", {
                staticStyle: { float: "right" },
                attrs: {
                  layout: "prev, pager, next",
                  total: _vm.total,
                  "page-size": 30,
                  "current-page": _vm.currentPage
                },
                on: {
                  "prev-click": _vm.handleSetCurrent,
                  "next-click": _vm.handleSetCurrent,
                  "current-change": _vm.handleSetCurrent
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: {},
          attrs: {
            data: _vm.ticketData,
            "header-align": "center",
            stripe: "",
            fit: "",
            size: "small"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "판매 / 파트너",
              prop: "channel_name",
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.channel_name))]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(scope.row.partner_name))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "구매일", prop: "date_order", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          scope.row.date_order.replace(" ", "<br>")
                        )
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "주문번호", prop: "order_seq", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.order_seq))]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowTickets(scope.row)
                          }
                        }
                      },
                      [_vm._v("티켓목록")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowWebTickets(scope.row)
                          }
                        }
                      },
                      [_vm._v("보기")]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "구매상품",
              prop: "product_name",
              "min-width": "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "수량", prop: "cnt_order", "min-width": "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.cnt_order))]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(scope.row.cnt_tickets) +
                          " / " +
                          _vm._s(scope.row.cnt_use) +
                          " 매"
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "고객명", prop: "user_name", "min-width": "50" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "휴대폰번호 / SMS",
              prop: "phone",
              "min-width": "110"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          scope.row.phone.replace(
                            /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                            "$1-$2-$3"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.is_send) +
                            "\n          "
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowSMSList(scope.row)
                              }
                            }
                          },
                          [_vm._v("이력")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "상태", prop: "status", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.cnt_refund == 0 && scope.row.cnt_use == 0
                      ? _c("div", { staticClass: "text-secondary" }, [
                          _vm._v("미사용")
                        ])
                      : scope.row.cnt_tickets == scope.row.cnt_use
                      ? _c("div", { staticClass: "text-primary" }, [
                          _vm._v("사용")
                        ])
                      : scope.row.cnt_tickets == scope.row.cnt_refund
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v("환불")
                        ])
                      : scope.row.cnt_tickets > scope.row.cnt_refund &&
                        scope.row.cnt_refund > 0
                      ? _c("div", { staticClass: "text-warning" }, [
                          _vm._v("일부환불")
                        ])
                      : scope.row.cnt_tickets > scope.row.cnt_use &&
                        scope.row.cnt_use > 0
                      ? _c("div", { staticClass: "text-warning" }, [
                          _vm._v("일부사용")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-dropdown",
                      {
                        attrs: { size: "mini" },
                        on: {
                          command: function($event) {
                            return _vm.handleOrderCommand($event, scope.row)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "mini" } },
                          [
                            _vm._v("\n            관리"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "use" } },
                              [_vm._v("사용처리")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "unuse" } },
                              [_vm._v("미사용처리")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "refund" } },
                              [_vm._v("환불처리")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "refundCancel" } },
                              [_vm._v("환불처리취소")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "transfer" } },
                              [_vm._v("양도")]
                            ),
                            _vm._v(" "),
                            _c("el-dropdown-item", { attrs: { divided: "" } }),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "sendSMS" } },
                              [_vm._v("문자재전송")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "sendCancelSMS" } },
                              [_vm._v("취소문자전송")]
                            ),
                            _vm._v(" "),
                            _c("el-dropdown-item", { attrs: { divided: "" } }),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "removeOrder" } },
                              [_vm._v("주문정보 삭제(티켓포함)")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-col", { attrs: { span: 8 } }),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _c("el-pagination", {
                staticStyle: { float: "right" },
                attrs: {
                  layout: "prev, pager, next",
                  total: _vm.total,
                  "page-size": 30,
                  "current-page": _vm.currentPage
                },
                on: {
                  "prev-click": _vm.handleSetCurrent,
                  "next-click": _vm.handleSetCurrent,
                  "current-change": _vm.handleSetCurrent
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "티켓목록",
            width: "85%",
            visible: _vm.visibleTickets
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleTickets = $event
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { margin: "0 10px 10px 10px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "mini",
                        disabled: _vm.ticketMultipleSelection.length == 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleUse(
                            _vm.ticketMultipleSelection,
                            true
                          )
                        }
                      }
                    },
                    [_vm._v("사용")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "mini",
                        disabled: _vm.ticketMultipleSelection.length == 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleUse(
                            _vm.ticketMultipleSelection,
                            false
                          )
                        }
                      }
                    },
                    [_vm._v("미사용")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "warning",
                        size: "mini",
                        disabled: _vm.ticketMultipleSelection.length == 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleRefund(
                            _vm.ticketMultipleSelection,
                            true
                          )
                        }
                      }
                    },
                    [_vm._v("환불")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "mini",
                        disabled: _vm.ticketMultipleSelection.length == 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleRefund(
                            _vm.ticketMultipleSelection,
                            false
                          )
                        }
                      }
                    },
                    [_vm._v("환불취소")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.selectedOrder.user_name) +
                      " /\n        " +
                      _vm._s(
                        (_vm.selectedOrder.phone || "").replace(
                          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                          "$1-$2-$3"
                        )
                      ) +
                      " /\n        " +
                      _vm._s(_vm.selectedOrder.cnt_order) +
                      " /\n        " +
                      _vm._s(_vm.selectedOrder.order_seq) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: {},
              attrs: {
                data: _vm.ticketList,
                "header-align": "center",
                stripe: "",
                fit: "",
                size: "small"
              },
              on: { "selection-change": _vm.handleTicketSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "45" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "바코드번호",
                  prop: "reserv_barcode",
                  "min-width": "90"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "상품명",
                  prop: "goods_name",
                  "min-width": "150"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "종류", prop: "ticket_type", width: "80" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "상태", prop: "sales_status", width: "70" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "처리", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.sales_status === "환불"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "info", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleRefund(
                                          scope.row.seq,
                                          false
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("환불취소")]
                                )
                              ],
                              1
                            )
                          : scope.row.sales_status === "미사용"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      size: "mini",
                                      disabled:
                                        scope.row.sales_status !== "미사용"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleUse(
                                          scope.row.seq,
                                          true
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("사용처리")]
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "warning",
                                      size: "mini",
                                      disabled:
                                        scope.row.sales_status !== "미사용"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleRefund(
                                          scope.row.seq,
                                          true
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("환불처리")]
                                )
                              ],
                              1
                            )
                          : _c(
                              "span",
                              [
                                Boolean(scope.row.return_use_time_detail)
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(scope.row.return_use_time_detail)
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "info",
                                      size: "mini",
                                      disabled:
                                        scope.row.sales_status !== "사용"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleUse(
                                          scope.row.seq,
                                          false
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("미사용처리")]
                                )
                              ],
                              1
                            )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { margin: "10px 0" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "mini",
                        disabled: _vm.ticketMultipleSelection.length == 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleUse(
                            _vm.ticketMultipleSelection,
                            true
                          )
                        }
                      }
                    },
                    [_vm._v("사용 처리")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "mini",
                        disabled: _vm.ticketMultipleSelection.length == 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleUse(
                            _vm.ticketMultipleSelection,
                            false
                          )
                        }
                      }
                    },
                    [_vm._v("미사용 처리")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "티켓보기",
            width: "375px",
            visible: _vm.visibleWebTicket
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleWebTicket = $event
            }
          }
        },
        [
          _c("iframe", {
            attrs: { width: "100%", height: "600", src: _vm.ticketUrl }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "SMS발송",
            width: "85%",
            visible: _vm.visibleSMSList
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleSMSList = $event
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { margin: "0 10px 10px 10px" } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _vm._v("\n         \n      ")
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.selectedOrder.user_name) +
                      " /\n        " +
                      _vm._s(
                        (_vm.selectedOrder.phone || "").replace(
                          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                          "$1-$2-$3"
                        )
                      ) +
                      " /\n        " +
                      _vm._s(_vm.selectedOrder.cnt_order) +
                      " /\n        " +
                      _vm._s(_vm.selectedOrder.order_seq) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: {},
              attrs: { data: _vm.SMSListData, stripe: "", size: "small" }
            },
            [
              _c("el-table-column", {
                attrs: { label: "고객명", prop: "user_name", "min-width": "80" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "휴대폰번호",
                  prop: "phone",
                  "min-width": "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.phone.replace(
                                /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                                "$1-$2-$3"
                              )
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "발송여부", prop: "isSend", "min-width": "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.is_send
                          ? _c("span", [_vm._v("발송")])
                          : _c("span", [_vm._v("미발송")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "발송결과",
                  prop: "send_result",
                  "min-width": "70"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.send_result
                          ? _c("span", [_vm._v("성공")])
                          : !scope.row.send_result
                          ? _c("span", [_vm._v("실패")])
                          : _c("span", [_vm._v("-")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "발송시간",
                  prop: "date_send",
                  "min-width": "120"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "코드", prop: "code", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.code) +
                            "\n          "
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function($event) {
                                _vm.$alert(
                                  scope.row.msg.replace(/\n/g, "<br>"),
                                  "",
                                  { dangerouslyUseHTMLString: true }
                                )
                              }
                            }
                          },
                          [_vm._v("내용보기")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "타입", prop: "type", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.type == "first"
                          ? _c("span", [_vm._v("전송")])
                          : !scope.row.type == "transfer"
                          ? _c("span", [_vm._v("양도")])
                          : !scope.row.type == "cancel"
                          ? _c("span", [_vm._v("취소")])
                          : _c("span", [_vm._v("-")])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }