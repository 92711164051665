"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.to-string");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

var _mockjs = _interopRequireDefault(require("mockjs"));

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var List = [];
var count = 100;
var baseContent = '<p>I am testing data, I am testing data.</p><p><img src="https://wpimg.wallstcn.com/4c69009c-0fd4-4153-b112-6cb53d1cf943"></p>';
var image_uri = 'https://wpimg.wallstcn.com/e4558086-631c-425c-9430-56ffb46e70b3';

for (var i = 0; i < count; i++) {
  List.push(_mockjs.default.mock({
    id: '@increment',
    timestamp: +_mockjs.default.Random.date('T'),
    author: '@first',
    reviewer: '@first',
    title: '@title(5, 10)',
    content_short: 'mock data',
    content: baseContent,
    forecast: '@float(0, 100, 2, 2)',
    importance: '@integer(1, 3)',
    'type|1': ['CN', 'US', 'JP', 'EU'],
    'status|1': ['published', 'draft', 'deleted'],
    display_time: '@datetime',
    comment_disabled: true,
    pageviews: '@integer(300, 5000)',
    image_uri: image_uri,
    platforms: ['a-platform']
  }));
}

var _default = [{
  url: '/article/list',
  type: 'get',
  response: function response(config) {
    var _config$query = config.query,
        importance = _config$query.importance,
        type = _config$query.type,
        title = _config$query.title,
        _config$query$page = _config$query.page,
        page = _config$query$page === void 0 ? 1 : _config$query$page,
        _config$query$limit = _config$query.limit,
        limit = _config$query$limit === void 0 ? 20 : _config$query$limit,
        sort = _config$query.sort;
    var mockList = List.filter(function (item) {
      if (importance && item.importance !== +importance) return false;
      if (type && item.type !== type) return false;
      if (title && item.title.indexOf(title) < 0) return false;
      return true;
    });

    if (sort === '-id') {
      mockList = mockList.reverse();
    }

    var pageList = mockList.filter(function (item, index) {
      return index < limit * page && index >= limit * (page - 1);
    });
    return {
      code: 20000,
      data: {
        total: mockList.length,
        items: pageList
      }
    };
  }
}, {
  url: '/article/detail',
  type: 'get',
  response: function response(config) {
    var id = config.query.id;

    var _iterator = _createForOfIteratorHelper(List),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var article = _step.value;

        if (article.id === +id) {
          return {
            code: 20000,
            data: article
          };
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
}, {
  url: '/article/pv',
  type: 'get',
  response: function response(_) {
    return {
      code: 20000,
      data: {
        pvData: [{
          key: 'PC',
          pv: 1024
        }, {
          key: 'mobile',
          pv: 1024
        }, {
          key: 'ios',
          pv: 1024
        }, {
          key: 'android',
          pv: 1024
        }]
      }
    };
  }
}, {
  url: '/article/create',
  type: 'post',
  response: function response(_) {
    return {
      code: 20000,
      data: 'success'
    };
  }
}, {
  url: '/article/update',
  type: 'post',
  response: function response(_) {
    return {
      code: 20000,
      data: 'success'
    };
  }
}];
exports.default = _default;