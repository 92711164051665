"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _mockjs = _interopRequireDefault(require("mockjs"));

var NameList = [];
var count = 100;

for (var i = 0; i < count; i++) {
  NameList.push(_mockjs.default.mock({
    name: '@first'
  }));
}

NameList.push({
  name: 'mock-Pan'
});
var _default = [// username search
{
  url: '/search/user',
  type: 'get',
  response: function response(config) {
    var name = config.query.name;
    var mockNameList = NameList.filter(function (item) {
      var lowerCaseName = item.name.toLowerCase();
      return !(name && lowerCaseName.indexOf(name.toLowerCase()) < 0);
    });
    return {
      code: 20000,
      data: {
        items: mockNameList
      }
    };
  }
}, // transaction list
{
  url: '/transaction/list',
  type: 'get',
  response: function response(_) {
    return {
      code: 20000,
      data: {
        total: 20,
        'items|20': [{
          order_no: '@guid()',
          timestamp: +_mockjs.default.Random.date('T'),
          username: '@name()',
          price: '@float(1000, 15000, 0, 2)',
          'status|1': ['success', 'pending']
        }]
      }
    };
  }
}];
exports.default = _default;