"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      data: [],
      dialogTitle: '등록',
      registDialog: false,
      nativeObj: {
        name: ''
      },
      registObj: {
        name: ''
      }
    };
  },
  created: function created() {
    this.handleSetData();
  },
  methods: {
    handleSetData: function handleSetData() {
      var _this = this;

      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/channel/list")).then(function (res) {
        var data = res.data;

        if (!data.result) {
          console.log(data.data);
        }

        _this.data = data.data.result;
      });
    },
    codeRegist: function codeRegist() {
      var _this2 = this;

      var data = this.registObj;

      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/channel/").concat(this.dialogTitle === '등록' ? 'add' : 'modify'), data).then(function (res) {
        var data = res.data;

        if (!data.result) {
          console.log(data.data);
        }

        _this2.handleSetData();

        _this2.registDialog = false;
      });
    },
    codeModify: function codeModify(val) {
      this.dialogTitle = '수정';
      this.registObj = val;
      this.registDialog = true;
    }
  }
};
exports.default = _default;