"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("/opt/build/repo/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vuex = require("vuex");

var _axios = _interopRequireDefault(require("axios"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  data: function data() {
    return {
      data: [],
      dialogTitle: '등록',
      registDialog: false,
      feedback: {},
      visibleWebTicket: false,
      ticketUrl: 'about:blank',
      checkin: {
        code: '',
        name: ''
      },
      nativeObj: function nativeObj() {
        return {
          name: '',
          email: '',
          password: '',
          confirm: '',
          options: [],
          checkin: [],
          data: {}
        };
      },
      registObj: {},
      inputOptionVisible: false,
      newOptionValue: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['token', 'roles'])), {}, {
    banner_top: function banner_top() {
      if (this.registObj.data.banner && this.registObj.data.banner.top) {
        return [this.registObj.data.banner.top];
      }

      return [];
    }
  }),
  watch: {
    'registObj.password': function registObjPassword(v) {
      if (!v || v.trim() === '') return;

      if (v.length < 8) {
        this.feedback.password = '비밀번호는 8자이상 입력하세요.';
      } else {
        this.feedback.password = '';
      }
    },
    'registObj.confirm': function registObjConfirm(v) {
      if (!v || v.trim() === '') return;

      if (this.registObj.password !== v) {
        this.feedback.confirm = '비밀번호가 같지 않습니다.';
      } else {
        this.feedback.confirm = '';
      }
    }
  },
  created: function created() {
    this.registObj = this.nativeObj();
    this.handleSetData();
  },
  methods: {
    handleSetData: function handleSetData() {
      var _this = this;

      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/partner/list"), {
        partner_id: this.token
      }).then(function (res) {
        var data = res.data;

        if (!data.result) {
          console.log(data.data);
        }

        _this.data = data.data.result;
      });
    },
    codeRegist: function codeRegist() {
      var _this2 = this;

      if (this.registObj.checkin.length === 0) {
        alert('체크인 코드를 입력해주세요.');
        this.$refs.checkinCode.focus();
        return;
      }

      var data = this.registObj;

      _axios.default.post("".concat(process.env.VUE_APP_SERVER, "/v2/admin/partner/").concat(this.dialogTitle === '등록' ? 'add' : 'modify'), data).then(function (res) {
        var data = res.data;

        if (!data.result) {
          console.log(data.data);
        } else {
          alert('저장했습니다.');
          _this2.registObj = data.data.data;
        }

        _this2.handleSetData(); // this.registDialog = false

      });
    },
    modify: function modify(val) {
      if (!val.options) val.options = [];
      if (!val.checkin) val.checkin = [];
      if (!val.data) val.data = {};
      if (!val.data.banner) val.data.banner = {};
      this.dialogTitle = '수정';
      this.registObj = val;
      this.registDialog = true;
    },
    delete: function _delete(val) {},
    handleAddCode: function handleAddCode() {
      if (this.checkin.code.length < 6) {
        alert('6자 이상 입력해주세요.');
        this.$refs.checkinCode.focus();
        return;
      }

      if (this.checkin.name === '') {
        alert('설명을 입력해주세요.');
        this.$refs.checkinName.focus();
        return;
      }

      this.registObj.checkin.push(_objectSpread({}, this.checkin));
      this.checkin = {};
    },
    handleRemoveCode: function handleRemoveCode(index) {
      this.registObj.checkin.splice(index, 1);
      this.$forceUpdate();
    },
    handleRemoveOption: function handleRemoveOption(index) {
      this.registObj.options.splice(index, 1);
    },
    showInputOption: function showInputOption() {
      var _this3 = this;

      this.inputOptionVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.saveOptionInput.$refs.input.focus();
      });
    },
    handleInputOption: function handleInputOption() {
      var inputValue = this.newOptionValue.trim();

      if (inputValue) {
        if (this.registObj.options.indexOf(inputValue) >= 0) return;
        this.registObj.options.push(inputValue);
      }

      this.inputOptionVisible = false;
      this.newOptionValue = '';
    },
    handleShowWebTickets: function handleShowWebTickets() {
      this.ticketUrl = "".concat(process.env.VUE_APP_FRONT, "/p_").concat(this.registObj.id, "?v=").concat(Date.now());
      this.visibleWebTicket = true;
    },
    handelUploadChange: function handelUploadChange(f) {
      var _this4 = this;

      var reader = new FileReader();
      reader.readAsDataURL(f.raw);

      reader.onload = function (e) {
        if (_this4.registObj.data.banner.top) {
          if (!_this4.registObj.data.banner.removed) _this4.registObj.data.banner.removed = [];

          if (_this4.registObj.data.banner.top.key) {
            _this4.registObj.data.banner.removed.push({
              Key: _this4.registObj.data.banner.top.key
            });
          }
        }

        _this4.registObj.data.banner.top = {
          size: f.size,
          name: f.name,
          type: f.raw.type,
          url: e.target.result
        };

        _this4.$forceUpdate();
      };
    },
    handelUploadRemove: function handelUploadRemove(f) {
      if (!this.registObj.data.banner.removed) this.registObj.data.banner.removed = [];
      if (f.key) this.registObj.data.banner.removed.push(f);
    }
  }
};
exports.default = _default;