var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("el-switch", {
        staticStyle: { "margin-right": "1rem" },
        attrs: { "active-text": "기간(일별)", "inactive-text": "기간(월별)" },
        model: {
          value: _vm.switchBtn,
          callback: function($$v) {
            _vm.switchBtn = $$v
          },
          expression: "switchBtn"
        }
      }),
      _vm._v(" "),
      _vm.switchBtn
        ? _c("el-date-picker", {
            staticStyle: { width: "250px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": "~",
              "start-placeholder": "시작(일)",
              "end-placeholder": "종료(일)",
              "picker-options": _vm.datePickerOptions
            },
            model: {
              value: _vm.value1,
              callback: function($$v) {
                _vm.value1 = $$v
              },
              expression: "value1"
            }
          })
        : !_vm.switchBtn
        ? _c("el-date-picker", {
            staticStyle: { width: "250px" },
            attrs: {
              type: "monthrange",
              align: "right",
              "unlink-panels": "",
              "range-separator": "~",
              "start-placeholder": "시작(월)",
              "end-placeholder": "종료(월)",
              "picker-options": _vm.monthPickerOptions
            },
            model: {
              value: _vm.value2,
              callback: function($$v) {
                _vm.value2 = $$v
              },
              expression: "value2"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.roles.indexOf("admin") >= 0 && Object.values(_vm.channels).length > 0
        ? _c(
            "el-select",
            {
              attrs: { placeholder: ":: 채널" },
              model: {
                value: _vm.channel_id,
                callback: function($$v) {
                  _vm.channel_id = $$v
                },
                expression: "channel_id"
              }
            },
            _vm._l(_vm.channels, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.text, value: item.value }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.roles.indexOf("admin") >= 0 && Object.values(_vm.partners).length > 0
        ? _c(
            "el-select",
            {
              attrs: { placeholder: ":: 파트너" },
              model: {
                value: _vm.partner_id,
                callback: function($$v) {
                  _vm.partner_id = $$v
                },
                expression: "partner_id"
              }
            },
            _vm._l(_vm.partners, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.text, value: item.value }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("p"),
      _vm._v(" "),
      _c("h4", { staticStyle: { "margin-bottom": "0" } }, [
        _vm._v("TICKET 통계")
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            "background-color": "#F7F9F9",
            "margin-bottom": "20px"
          }
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.totalData,
                "header-cell-style": _vm.ticketHeaderStyle,
                "header-align": "center",
                border: "",
                align: "right"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "총 판매금액", prop: "amount" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "총 주문수량", prop: "orders" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "총 티켓수량", prop: "tickets" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "총 사용수량", prop: "used" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "총 취소수량", prop: "cancelled" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "총 환불수량", prop: "refund" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.partnerData, function(item) {
        return _c(
          "el-row",
          {
            key: item.id,
            staticStyle: {
              "background-color": "#F7F9F9",
              "margin-bottom": "20px"
            }
          },
          [
            _c("h3", { staticStyle: { "padding-left": "24px" } }, [
              _vm._v(
                _vm._s(
                  (
                    _vm.partners
                      .filter(function(o) {
                        return o.value == item.id
                      })
                      .pop() || {}
                  ).text
                )
              )
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                attrs: {
                  data: Object.values(item.channels),
                  "header-align": "center",
                  stripe: "",
                  fit: "",
                  size: "small",
                  align: "right"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "판매처",
                    prop: "channel_id",
                    "min-width": "120"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  (
                                    _vm.channels
                                      .filter(function(o) {
                                        return o.value == scope.row.channel_id
                                      })
                                      .pop() || {}
                                  ).text
                                ) +
                                "\n        "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "판매금액", prop: "amount" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "주문수량", prop: "orders" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "티켓수량", prop: "tickets" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "사용수량", prop: "used" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "취소수량", prop: "cancelled" }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "환불수량", prop: "refund" }
                })
              ],
              1
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("hr", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: { size: "1" }
      }),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            "background-color": "#F7F9F9",
            "margin-bottom": "20px"
          }
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.dailyData,
                "header-align": "center",
                stripe: "",
                fit: "",
                size: "small",
                align: "right"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "날짜", prop: "date", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              new Date(scope.row.date).toLocaleDateString()
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "판매처",
                  prop: "channel_id",
                  "min-width": "90"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              (
                                _vm.channels
                                  .filter(function(o) {
                                    return o.value == scope.row.channel_id
                                  })
                                  .pop() || {}
                              ).text
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "판매금액", prop: "amount" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "주문수량", prop: "orders" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "티켓수량", prop: "tickets" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "사용수량", prop: "used" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "취소수량", prop: "cancelled" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "환불수량", prop: "refunds" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }