"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/opt/build/repo/node_modules/core-js/modules/es6.array.iterator.js");

require("/opt/build/repo/node_modules/core-js/modules/es6.promise.js");

require("/opt/build/repo/node_modules/core-js/modules/es6.object.assign.js");

require("/opt/build/repo/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("./icons");

require("./permission");

require("./utils/error-log");

var filters = _interopRequireWildcard(require("./filters"));

var _mock = require("../mock");

// a modern alternative to CSS resets
// global css
// icon
// permission control
// error log
// global filters

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
if (process.env.NODE_ENV === 'production') {
  (0, _mock.mockXHR)();
}

_vue.default.use(_elementUi.default, {
  locale: _en.default
});

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size

}); // register global utility filters


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});